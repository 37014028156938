<template>
  <div>
    <h3 class="text_navy title">
      Select Accessories Type
    </h3>

    <label class="form__label">
      <input type="text" class="form__input"
             v-model="models.type"
             placeholder="Type To Search"
      >
    </label>

    <div class="row">
      <div v-for="type in typesWithStyles" class="col-12 col-lg-6">
        <h4 class="text">{{type.name}}</h4>
        <ul class="list ">
          <li class="list__item"
              v-for="item in sortStyles(type.styles)"
              :key="item.id"
              @click="select(item, type)"
          >
            {{item.name}}
          </li>
        </ul>
      </div>
    </div>

    </div>

</template>
<script>
  import {accessoriesTypeSearch} from "../../../helpers/search";

  export default {
    name: "AccessoriesType",
    data() {
      return {
        models: {
          type: ''
        },
        typesWithStyles: []
      }
    },
    props: ['accessory'],

    watch: {
      'models.type'(n) {
        this.typesWithStyles = accessoriesTypeSearch(n, this.accessory.data);
      },
      accessory() {
        this.typesWithStyles = this.accessory.data;
      }
    },

    methods: {
      sortStyles(items) {
        return _.orderBy(items || {}, ['sort_order', 'name'], ['asc', 'asc'])
      },

      select(item, type) {
        this.$emit('selectType', item, type);
      },

      back(){
        this.$store.commit('accessories/mode', 'all');
      }
    },

    created(){
        this.typesWithStyles = this.accessory.data;
    },
  }
</script>

<style lang="scss" scoped>

  @import "../../../assets/scss/utils/vars";

  .form {
    &__input {
      border: none;
      border-bottom: 2px solid $navy;
    }
  }
</style>
